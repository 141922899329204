import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/sections/hero"
import Articles from "../components/sections/articles" 
import About from "../components/sections/about"
import Interests from "../components/sections/interests"
import Blogs from "../components/sections/blog"
import Contact from "../components/sections/contact"
import { splashScreen } from "../config"

const BlogPage = ({ data }) => {
    console.log(data.blogPosts.edges)
    console.log(data.blogPosts.edges[0].node.body)
    return(   
  <Layout splashScreen={splashScreen}>
    <h1>Blog</h1>
    <Blogs content={data.blogPosts.edges} />

  </Layout>)
}

BlogPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default BlogPage

export const pageQuery = graphql`
{
  blogPosts: allMdx(filter: {fileAbsolutePath: {regex: "/blog/"}, frontmatter: {visible: {eq: "true"}}}, sort: {fields: [frontmatter___position], order: ASC}) {
    edges {
      node {
        body
        frontmatter {
          title
          category
          emoji
          external
          github
          screenshot {
            childImageSharp {
              fluid(maxWidth: 400, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          tags
          position
          buttonVisible
          buttonUrl
          buttonText
        }
      }
    }
  }
  
}
`
